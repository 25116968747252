var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "gsap" },
    [
      _c("h1", { staticClass: "heading" }, [_vm._v("GSAP Animation")]),
      _vm._m(0),
      _c(
        "transition-group",
        {
          staticClass: "gsap__container",
          attrs: { appear: "", css: false, tag: "section" }
        },
        _vm._l(_vm.blocks, function(block, index) {
          return _c("div", { key: index, staticClass: "gsap__block" }, [
            _vm._v(" " + _vm._s(block) + " ")
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v("Using the "),
      _c(
        "a",
        {
          staticClass: "gsap__link",
          attrs: { href: "https://greensock.com/gsap/", target: "_blank" }
        },
        [_vm._v("GSAP")]
      ),
      _vm._v(
        " JavaScript animation library to animate 'in' a certain amount of blocks. These are positioned using CSS Grid."
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }